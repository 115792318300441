import {AfterViewInit, Component, ElementRef, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {Configurations} from '../../../../configurations';
import {DataProviderService} from '../../../controller/services/data-provider.service';
import {MonitoringService} from '../../../controller/services/monitoring.service';
import {NavigationService} from '../../../controller/services/navigation.service';
import {CSystem} from '../../../model/system.model';
import {GetThingDataService} from '../../../controller/services/get-thing-data.service';
import {environment} from '../../../../../environments/environment';
import {NgxQrcodeElementTypes} from '@techiediaries/ngx-qrcode';
import {AuthenticationService} from '../../../controller/services/authentication.service';

@Component({
    selector: 'app-monitoring-thing',
    templateUrl: './monitoring-thing.component.html',
    styleUrls: ['./monitoring-thing.component.scss',
        '../monitoring.scss']
})
/**
 * Komponente in der die einzelnen Thing Cards angezeigt werden
 */
export class MonitoringThingComponent implements OnInit, OnDestroy, AfterViewInit
{
    /**
     * Anlage von der die Things geladen werden
     */
    public system: CSystem;
    public isAdmin: boolean;

    public isOneLine = true;

    public thingIds = '';
    public elementType = NgxQrcodeElementTypes.IMG;

    @ViewChild('banner') banner: ElementRef;

    /**
     * Konstruktor
     * @param data
     * @param nav
     * @param _route
     * @param location
     * @param monitoring
     */
    constructor(public data: DataProviderService,
                public getdata: GetThingDataService,
                public nav: NavigationService,
                private _route: ActivatedRoute,
                public monitoring: MonitoringService,
                private auth: AuthenticationService,)
    {
        this.isAdmin = auth.isAdmin();
    }

    /**
     * Setzt die aktiven Customer Detail und holt das System
     */
    ngOnInit()
    {
        Configurations.InitaliseComplete.subscribe(x =>
        {
            if (x)
            {
                this._route.paramMap.subscribe(params =>
                {
                    this.nav.setActiveCustomerDetails();

                    this.data.customer.forEach(customer =>
                    {
                        if (customer.name === params.get('company'))
                        {
                            customer.locations.forEach(location =>
                            {
                                if (location.name === params.get('location'))
                                {
                                    location.systems.forEach(system => {
                                        if (system.name === params.get('system'))
                                        {
                                            this.system = system;
                                            this.thingIds = this.system.getAllThings().map(thing => '\"' + thing.id + '\"').toString();
                                        }
                                    });
                                }
                            });
                        }
                    });


                    this.nav.addDashboardTabToFirstIndex();

                    this.nav.MonitoringNavigation = true;

                    this.monitoring.getCurrentProperties(this.system.id)
                        .then((res) =>
                    {
                        for (const thing of this.system.getAllThings())
                        {
                            if (res.find((z) => z.key === thing.id))
                            {
                            }
                            else
                            {
                                thing.hasCurrentProperties = false;
                            }
                        }

                    });
                });
            }
        });
    }

    ngAfterViewInit(): void
    {
        if (this.banner == null) return;
        this.isOneLine = this.banner.nativeElement.offsetHeight <= 40;
    }

    /**
     * Setzt Monitoring Navigation auf false
     */
    ngOnDestroy(): void
    {
        this.nav.MonitoringNavigation = false;
    }

    scrollToAnchor(elementId): void
    {
        const content = document.getElementById('content');
        const element = document.getElementById(elementId);
        const headerOffset = 25;
        // Abstand zum Seitenanfang
        const topOffset = element.parentElement.parentElement.offsetTop - headerOffset;

        content.scrollTo({
            top: topOffset,
            behavior: 'smooth'
        });
    }

    protected readonly environment = environment;
}
